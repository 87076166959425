*,
body {
  margin: 0;
}
.header {
  width: 100%;
  height: 160px;
  background-color: #f06;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 225px;
}
.content {
  font-family: 'Roboto', sans-serif;
}
a {
  color: #18BFFF;
}
h1,
h2 {
  text-align: center;
}
h1 {
  margin: 70px auto 0;
  font-size: 36px;
  text-transform: capitalize;
}
h2 {
  margin: 25px auto 0;
  font-size: 16px;
}
.content-block {
  width: 600px;
  margin: 50px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-image {
  margin-right: 18px;
}
.content-image img {
  display: block;
  height: 55px;
  margin: 0 auto;
}

.container {
  background-color: #000;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 80px;
  width: fit-content;
}

.numbered-steps-wrapper > div {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 2rem;
}

.num-circle {
  background: #5E53C5;
  border-radius: 50%;
  font-size: 1rem;
  height: 20px;
  margin-right: 20px;
  padding: 10px;
  text-align: center;
  width: 20px;
}

.page-container {
  background-color: #000;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  height: 100vh;
  padding: 0 10%;
}

.page-container .logo {
  margin: 30px 0 0;
}
