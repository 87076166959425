*,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.download-done-content {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-bottom: 50px;
}

.download-done-content img {
  margin-left: 50px;
}

.download-done-text {
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-align: left;
}

.download-done-text.small {
  font-size: 1rem !important;
  line-height: 24px !important;
  margin-bottom: 2rem;
}

.download-done-h1.download-done-text {
  font-size: 2.5rem !important;
  margin-bottom: 45px;
  margin: 30px 0 30px 0;
  line-height: 3rem !important;
  text-transform: none;
}

.download-done-content .download-done-text {
  font-size: 1.5rem;
  line-height: 30px;
}

.download-done-content .numbered-steps-wrapper {
    margin-bottom: 4rem;
}

@media (max-width: 1024px) {
  .download-done {
    height: 100%;
    width: fit-content;
  }

  .download-done-content {
    flex-direction: column;
  }

  .download-done img {
    margin-bottom: 0;
  }
  
  .download-done-content img {
    margin-top: 30px;
  }
}