
:root {
    --dropdown-button-width: 32px;
}

.download-button {
    position: relative;
    background: #5E53C5;
    border-radius: 8px;
    padding-right: calc(4px + var(--dropdown-button-width));
    width: fit-content;
}

.download-button.dropdown-open, .download-button.dropdown-open > a:hover {
    border-radius: 8px 8px 0 0;
}

.download-button a {
    display: block;
    background: none;
    border: none;
    border-radius: 8px;
    color: #fff !important;
    padding: 12px 20px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    text-align: left;
    text-decoration: none !important;
    transition: background-color 0.5s ease;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.download-button button {
    transition: background-color 0.5s ease;
}

.download-button a:hover, .download-button button:hover {
    background-color: #3628B6;
}

.download-button a:hover {
    border-radius: 8px 0 0 8px;
}

.download-button .dropdown-toggle {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40px;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #5E53C5;
    justify-content: center;
}

.dropdown-toggle {
    border-radius: 0 8px 8px 0;
}

.alternative-download-options {
    display: none;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    width: 100%;
}

.alternative-download-options.dropdown-open {
    position: absolute;
    background: #333;
    margin-top: 3px;
    display: flex;
    flex-direction: column;
}

.alternative-download-options a {
    font-weight: normal;
    display: block;
    border-radius: 0;
}

.alternative-download-options a:hover {
    background-color: #555;
    border-radius: 0;
}
