*,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.app-div {
  background-color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-download .app-h1 {
  text-transform: none;
}

.app-text-container {
  width: 636px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-text {
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin-bottom: 45px;
}

.app-h1 {
  margin: 30px 0 30px 0;
  font-weight: 700;
  font-size: 40px;
}

.app-p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.app-button {
  background: #fff;
  border: 2px solid #dd2770;
  border-radius: 8px;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 16px;
  color: #dd2770;
  cursor: pointer;
  transition: background-color 0.5s ease;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.app-button:hover {
  background-color: #fadee9;
}

.open-app-button {
  position: relative;
  background: #5E53C5;
  border-radius: 8px;
  margin-bottom: 45px;
  width: fit-content;
}

.open-app-button a {
  display: block;
  background: none;
  border: none;
  border-radius: 8px;
  color: #fff !important;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  transition: background-color 0.5s ease;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.open-app-button a:hover {
  background-color: #3628B6;
  border-radius: 8px;
}