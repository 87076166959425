*,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.container .row {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.app-header {
  justify-content: space-between;
}

.app-content > div {
  margin-top: 100px;
}

.app-content .row {
  justify-content: space-evenly;
}

.app-download {
  display: flex; 
  align-items: center;
  justify-content: space-evenly;
}

.download-app-text {
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 45px;
  font-size: 1.5rem;
}

.app-download-h1 {
  margin: 30px 0 30px 0;
  font-weight: 700;
  font-size: 30px;
  text-align: left;
}

.app-benefits.row {
  align-items: start;
  gap: 1.6rem;
}

.app-benefits p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1rem 0;
} 

.app-benefits div {
  align-self: stretch;
  background: #151515;
  border-radius: 8px;
  flex-basis: 100%;
  padding: 24px;
}

.app-benefits ul {
  padding-inline-start: 25px;
}

.app-benefits li {
  margin-top: 0.5rem;
}

p.small-text {
  font-size: 0.8rem;
  margin: 0
}
