*,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.disconnect-app-content {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-bottom: 50px;
}

.disconnect-app-content img {
  margin-left: 50px;
}

.disconnect-app-text {
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-align: left;
}

.disconnect-app-text.small {
  font-size: 1rem !important;
  line-height: 24px !important;
  margin-bottom: 50px;
}

.disconnect-app-h1.disconnect-app-text {
  font-size: 2.5rem !important;
  margin-bottom: 45px;
  margin: 30px 0 30px 0;
  line-height: 3rem !important;
  text-transform: none;
}

.disconnect-app-content .disconnect-app-text {
  font-size: 1.5rem;
  line-height: 30px;
}

.disconnect-app-button {
  position: relative;
  background: #5E53C5;
  border-radius: 8px;
  width: fit-content;
}

.disconnect-app-button a {
  display: block;
  background: none;
  border: none;
  border-radius: 8px;
  color: #fff !important;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  transition: background-color 0.5s ease;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.disconnect-app-button button {
  transition: background-color 0.5s ease;
}

.disconnect-app-button a:hover, .disconnect-app-button button:hover {
  background-color: #3628B6;
  border-radius: 8px;
}

.disconnect-app-content .numbered-steps-wrapper {
  margin: 50px 0;
}

@media (max-width: 1024px) {
  .disconnect-app {
    height: 100%;
    width: fit-content;
  }

  .disconnect-app-content {
    flex-direction: column;
  }

  .disconnect-app img {
    margin-bottom: 0;
  }
  
  .disconnect-app-content img {
    margin-top: 30px;
  }
}
